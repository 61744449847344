import React, { useState, useEffect } from "react";
import A_EnhancedTable from "../../../Atoms/DataDisplay/DataGrid/A_EnhancedTable";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Header from "../../PageEditor/MainPage/Header/Header";
import * as tenantsActions from "../../../../../store/actions/tenantsActions";
import { useHistory } from "react-router-dom";
import * as chartEditorActions from "../../../../../store/actions/chartEditorActions";
import * as currentChartActions from "../../../../../store/actions/currentChartActions";
import * as chartPreviewActions from "../../../../../store/actions/chartPreviewActions";
import A_Button from "../../../Atoms/Inputs/Buttons/A_Button";
import A_Snackbar from "../../../Atoms/Feedback/Snackbars/A_Snackbar";
import A_SimpleDialog from "../../../Atoms/Feedback/Dialogs/A_SimpleDialog";
import CONSTANTS from "../../../../../Utils/Helpers/constants";
import { makeStyles, CircularProgress, Typography } from "@material-ui/core";
import { AlertTitle, Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
    loadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "200px",
    },
    centerText: {
        textAlign: "center",
        marginTop: "10px",
    },
}));

let colmunConfiguration = {
    title: "",
    noDataText: CONSTANTS.NO_DATA_AVAILABLE,
    paginate: true,
    searchable: true,
    sortable: false,
    striped: true,
    addButton: true,
    action: {
        edit: true,
        delete: true,
    },
    columnConfig: {
        name: {
            id: "name",
            label: CONSTANTS.NAME,
            sorting: false,
            hide: false,
        },
        description: {
            id: "description",
            label: CONSTANTS.DESCRIPTION,
            sorting: false,
            hide: false,
        },
        type: {
            id: "type",
            label: CONSTANTS.TYPE,
            sorting: false,
            hide: false,
        },
    },
};

const ChartEditor = (props) => {
    const classes = useStyles();
    const defaultFormData = {
        chartId: null,
        id: null,
        chartName: "",
        description: "",
        type: "graph",
        javaScript: "",
        configuration: "{}",
        query: {
            source: "",
            spName: "",
            apiName: "",
            parameters: [],
        },
        resultParameters: "",
    };
    const history = useHistory();
    const [currentTenant, setCurrentTenant] = React.useState(
        props.chartEditor.currentTenant ? props.chartEditor.currentTenant : ""
    );
    const [changesSaved, setChangesSaved] = useState({
        status: false,
        msg: CONSTANTS.CHART_DELETED_SUCCESSFUL,
    });
    const [deletePopup, setDeletePopup] = React.useState(false);
    const [currenteleId, setCurrenteleId] = React.useState("");

    const [chartData, setChartData] = useState(false);
    let chartEditorData =
        props.chartEditor &&
        props.chartEditor.data &&
        props.chartEditor.data != "Empty"
            ? props.chartEditor.data
            : [];

    let diaplayFilteredChartsData = chartEditorData.map((item) => {
        return {
            name: item.chartName,
            description: item.description,
            type: item.type,
            id: item.id,
        };
    });

    const tenantChangeHandler = async (e) => {
        if (e) {
            props.getCharts(e);
            setCurrentTenant(e);
        }
    };

    React.useEffect(() => {
        props.getTenants();
        if (props.chartEditor && props.chartEditor.currentTenant) {
            props.getCharts(props.chartEditor.currentTenant);
        }
    }, []);

    React.useEffect(() => {
        setChartData(true);
    }, [chartEditorData]);

    const handleAddButtonClick = (e) => {
        e.preventDefault();
        let path = "/ChartConfigurationForm/" + 0;
        props.setCurrentChart(defaultFormData);
        props.setChartPreview(defaultFormData);
        history.push({ pathname: path });
    };
    const handleEditButtonClick = (e) => {
        let arr = props.chartEditor.data.filter(
            (item) => e.currentTarget.id == item.id
        );
        let id = arr[0].id;
        let path = "/ChartConfigurationForm/" + id;
        props.setCurrentChart(arr[0]);
        props.setChartPreview(arr[0]);
        history.push({ pathname: path });
    };

    const handleSnackbarClose = () => {
        setChangesSaved({ status: false, msg: "" });
    };

    const handleConfrimDelete = () => {
        setChangesSaved({ status: true, msg: CHART_DELETED_SUCCESSFUL });
        props.deleteCharts({
            id: currenteleId,
            tenantId: props.chartEditor.currentTenant,
        });
        setDeletePopup(false);
    };
    const handleCancelDelete = () => {
        setDeletePopup(false);
    };

    const handleDeleteButtonClick = (e) => {
        let id = e.currentTarget.getAttribute("dataid");

        let arr = props.chartEditor.data.filter((item) => id == item.id);

        let chartId = arr[0].id;
        setCurrenteleId(chartId);
        setDeletePopup(true);
    };

    const getActionItems = () => {
        return (
            <>
                <A_Button
                    color="primary"
                    label={CONSTANTS.OK}
                    autoFocus
                    onClick={handleConfrimDelete}
                />

                <A_Button
                    color="default"
                    label={CONSTANTS.CANCEL}
                    onClick={handleCancelDelete}
                />
            </>
        );
    };
    return (
        <>
            <Header
                id="toolbar"
                title={CONSTANTS.CHART_EDITOR}
                TenantData={props.tenants}
                value={currentTenant}
                isdisabledFlag={
                    props.chartEditor && props.chartEditor.getChartStarted
                }
                tenantChangeHandlerCallback={(e, name) =>
                    tenantChangeHandler(e, name)
                }
            />
            {!currentTenant &&
                props.chartEditor &&
                !props.chartEditor.currentTenant && (
                    <Typography variant="h6" className={classes.centerText}>
                        Please choose a Client
                    </Typography>
                )}

            {currentTenant &&
                props.chartEditor &&
                props.chartEditor.getChartStarted && (
                    <div className={classes.loadingContainer}>
                        <CircularProgress />
                    </div>
                )}

            {currentTenant &&
            props.chartEditor &&
            !props.chartEditor.fetchChartsFailed &&
            !props.chartEditor.getChartStarted &&
            diaplayFilteredChartsData &&
            diaplayFilteredChartsData.length > 0 &&
            !props.fetchChartsFailed > 0 ? (
                <A_EnhancedTable
                    data={
                        diaplayFilteredChartsData &&
                        diaplayFilteredChartsData != "Empty"
                            ? diaplayFilteredChartsData
                            : {}
                    }
                    configurations={colmunConfiguration}
                    handleAddButtonClick={handleAddButtonClick}
                    handleEditButtonClick={handleEditButtonClick}
                    handleDeleteButtonClick={handleDeleteButtonClick}
                    chartData={chartData}
                />
            ) : (
                ""
            )}
            <A_SimpleDialog
                id="2"
                open={deletePopup}
                onClose={setDeletePopup}
                dialogContent={CONSTANTS.DELETE_POP_UP_TEXT}
                dialogActions={getActionItems()}
            />
            <A_Snackbar
                open={changesSaved.status}
                message={changesSaved.msg}
                autoHideDuration={5000}
                horizontal="center"
                handleClose={handleSnackbarClose}
            />
            {props.chartEditor.fetchChartsFailed && (
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    <strong>{props.chartEditor.msg}</strong>
                </Alert>
            )}
        </>
    );
};

function mapStateToProps(state) {
    return {
        user: state.oidc.user,
        tenants: state.tenants.data,
        chartEditor: state.chartEditor,
        currentChart: state.currentChart,
        chartPreview: state.chartPreview,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return bindActionCreators(
        Object.assign(
            {},
            chartEditorActions,
            tenantsActions,
            currentChartActions,
            chartPreviewActions
        ),
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(ChartEditor);
